<template>
  <div class="conGrad">
    <v-layout wrap justify-center pa-sm-16>
      <v-flex xs12 lg10 xl8 align-self-center py-4 px-sm-16 px-md-6 px-lg-16>
        <v-card :min-height="$vuetify.breakpoint.name=='xl'?'80vh':'50vh'" flat 
          color="transparent" >
          <v-layout wrap justify-center pt-md-12 pt-xl-16>
            <v-flex xs12 md6 lg6 align-self-center pt-xl-16>
              <Cards />
            </v-flex>
            <v-flex xs12 md6 lg6 pl-4 pl-sm-16 pt-12 pt-sm-10 pt-md-0 pt-xl-16 align-self-center>
              <v-layout wrap>
                <v-flex xs12 py-7 align-self-center text-left>
                  <span class="con1">Contact Us</span>
                </v-flex>
                <v-flex xs4 lg1 py-2 align-self-center text-left>
                  <v-img
                    width="25px"
                    height="30px"
                    contain
                    :src="require('./../../assets/Home/locatxl.png')"
                  ></v-img>
                </v-flex>
                <v-flex pb-6 xs12 align-self-center text-left>
                  <span class="con2">
                    Startups Valley TBI <br />
                    Amal Jyothi College of Engineering <br />
                    Kanjirappally, Kottayam 686518, Kerala, India
                  </span>
                </v-flex>
                <v-flex xs4 lg1 py-2 align-self-center text-left>
                  <v-img
                    width="25px"
                    height="30px"
                    contain
                    :src="require('./../../assets/Home/chat2x.png')"
                  ></v-img>
                </v-flex>
                <v-flex xs12 pb-6 align-self-center text-left>
                  <span class="con2">
                    Info@leopardtechlabs.com <br />
                    leopardtechlabs@gmail.com
                  </span>
                </v-flex>
                <v-flex xs12 py-12 align-self-center text-left>
                  <v-layout wrap justify-start>
                    <v-flex xs2 sm1 lg1 pr-2 align-self-center text-left>
                      <v-img
                        height="24px"
                        contain
                        :src="require('./../../assets/Home/fb.svg')"
                      ></v-img>
                    </v-flex>
                    <v-flex xs2 sm1 lg1 pr-2 align-self-center text-left>
                      <v-img
                        height="24px"
                        contain
                        :src="require('./../../assets/Home/ln.svg')"
                      ></v-img>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import Cards from "./conCard";
export default {
  components: {
    Cards,
  },
};
</script>

<style  scoped>
.conGrad {
  /* background-image: transparent */

  /* background-image: linear-gradient(#0d0d0d, #1e1e1e); */
  background: transparent
    linear-gradient(104deg, #0d0d0d 0%, #1e1e1e 100%, transparent 100%);

  /* linear-gradient(180deg, #0d0d0d 0%, #1e1e1e 100%) 0% 0% no-repeat
    padding-box;
  opacity: 1; */
  /* background-color: orange; */
}
</style>