<template>
  <div>
     <PageLoader :storage="appLoading" />
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeOut">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center px-3 px-sm-12>
      <v-flex xs12 align-self-center>
        <v-card :rounded="true" light v-if="!flag">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-layout wrap py-8>
              <v-flex xs12 px-4 px-sm-8 align-self-center text-left>
                <span class="con3">Get in touch</span>
              </v-flex>
              <v-flex xs12 px-4 px-sm-8>
                <v-layout wrap>
                  <v-flex xs12 text-left>
                    
                    <v-text-field
                      class="tes"
                      label="Name"
                      v-model="contact.name"
                      :rules="nameRules"
                      required
                      flat
                      solo
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 pb-4 align-self-center>
                <v-divider></v-divider>
              </v-flex>

              <v-flex xs12 px-4 px-sm-8>
                <v-layout wrap>
                  <!-- <v-flex xs12 text-left>
                  <span class="con4">Email</span>
                </v-flex> -->
                  <v-flex xs12 text-left>
                    <v-text-field
                      required
                      :rules="emailRules"
                      class="tes"
                      label="Email"
                      v-model="contact.email"
                      flat
                      solo
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 pb-4 align-self-center>
                <v-divider></v-divider>
              </v-flex>

              <v-flex xs12 px-4 px-sm-8>
                <v-layout wrap>
                  <!-- <v-flex xs12 text-left>
                  <span class="con4">Phone Number</span>
                </v-flex> -->
                  <v-flex xs12 text-left>
                    <v-text-field
                      class="tes"
                      :rules="phoneRules"
                      label="Phone Number"
                      v-model="contact.phone"
                      flat
                      solo
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 pb-4 align-self-center>
                <v-divider></v-divider>
              </v-flex>

              <v-flex xs12 px-4 px-sm-8>
                <v-layout wrap>
                  <!-- <v-flex xs12 text-left>
                  <span class="con4">Interested in..</span>
                </v-flex> -->
                  <v-flex xs12 text-left>
                    <v-select
                      class="tes"
                      label="Interested in.."
                      required
                      v-model="contact.interestedIn"
                      :items="interests"
                      item-text="name"
                      item-value="_id"
                      flat
                      solo
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 pb-4 align-self-center>
                <v-divider></v-divider>
              </v-flex>

              <v-flex xs12 px-4 px-sm-8>
                <v-layout wrap>
                  <!-- <v-flex xs12 text-left>
                  <span class="con4">Message</span>
                </v-flex> -->
                  <v-flex xs12 text-left>
                    <v-text-field
                      class="tes"
                      v-model="contact.message"
                      label="Message"
                      flat
                      solo
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 align-self-center>
                <v-spacer></v-spacer>
              </v-flex>

              <v-flex px-8 align-self-end text-right>
                <v-btn
                  @click="validate"
                  :disabled="!valid"
                  class="graddy"
                  rounded
                  :ripple="false"
                >
                  <span>Send</span>
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card>

        <!-- ELSE  -->

        <v-card class="tanGrad rot" :rounded="true" dark v-if="flag">
          <v-layout wrap pa-8>
            <v-flex xs12 pb-1 align-self-end text-right>
              <v-btn plain @click="flag = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-flex>
            <v-flex
              xs12
              xl6
              pt-16
              pb-0
              style="padding-top: 180px !important"
              align-self-center
              text-left
            >
              <span class="con5">Thank You.</span>
            </v-flex>
          </v-layout>

          <v-layout wrap py-2>
            <v-flex xs12 align-self-center>
              <v-divider></v-divider>
            </v-flex>
          </v-layout>
          <v-layout wrap pa-8>
            <v-flex xs12 lg8 xl6 py-0 align-self-center text-left>
              <span class="con6"> We’ll be in touch. Shortly! </span>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
// import Cards from "@/components/Home2/Cards";
export default {
  data() {
    return {
      flag: false,
      valid: true,
      contact: {
        name: "",
        message: "",
        phone: "",
        email: "",
        interestedIn: null,
      },
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 30 || "Name must be less than 30 characters",
      ],
    
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
        phoneRules: [
        (v) => !!v || "Phone is required",
        (v) => v.length == 10 || "Invalid Phone Number",
      ],
      interests: [],
      showSnackBar: false,
      timeOut: 5000,
      msg: null,
    };
  },
  components: {
    // Cards,
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/contact/interested/getlist",
      })
        .then((response) => {
          if (response.data.status) {
            this.interests = response.data.data;
            this.interestedIn=this.interests[0]._id
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    validate() {
      if (this.$refs.form.validate()) this.addContact();
    },
    addContact() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/contact/add",
        data: this.contact,
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            Object.keys(this.contact).forEach(
              (key) => (this.contact[key] = null)
            );
            this.flag = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style  scoped>
.conGrad {
  /* background-image: transparent */

  /* background-image: linear-gradient(#0d0d0d, #1e1e1e); */
  background: transparent
    linear-gradient(104deg, #0d0d0d 0%, #1e1e1e 100%, transparent 100%);

  /* linear-gradient(180deg, #0d0d0d 0%, #1e1e1e 100%) 0% 0% no-repeat
    padding-box;
  opacity: 1; */
  /* background-color: orange; */
}
.tanGrad {
  background: transparent
    linear-gradient(100deg, #1d1d1d 0%, #262626 100%, transparent 100%);
}
/* .tanGrad:hover {
  
  transition:  transform 1s;
  transform: rotateY(360deg);
} */
.graddy {
  background: transparent
    linear-gradient(
      100deg,
      #f5a624 0%,
      #f9b52c 44%,
      #fbba2f 59%,
      #fdc033 78%,
      #fdc033 78%,
      #ffc837 100%
    );
}
.rot {
  animation: fadeInAnimation ease 5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* .rot:hover {
  transform: rotate(360deg);
} */
</style>